import { render } from "datocms-structured-text-to-plain-text"
import React from "react"
import { StructuredText } from "react-datocms"

import { PanelContent } from "../styles/ConversionalPanelV2.styled"

const ConversionPanelContent = ({ component, pricing }) => {
  const headingSize = component?.headingSize.replace(/\s/g, "-").toLowerCase()
  return (
    <PanelContent className="conversion" pricing={pricing}>
      {component?.eyebrow && <p className="eyebrow">{component?.eyebrow}</p>}
      {component?.heading && (
        <h2 className={`heading ${headingSize}`}>{component.heading}</h2>
      )}
      {render(component?.conversionBody)?.length > 0 && (
        <div className="description">
          <StructuredText data={component?.conversionBody} />
        </div>
      )}
    </PanelContent>
  )
}

export default ConversionPanelContent
