import { Link } from "gatsby"
import styled, { css } from "styled-components"

import { color } from "../../../atoms/colors"
import ConcaveBackground from "../../../images/ConversionPanelBackgrounds/Bottom-Background.svg"
import ConvexBackground from "../../../images/ConversionPanelBackgrounds/ConvexCurveBackground.svg"

const theme = {
  dark: `
    background: linear-gradient(107.21deg, ${color.common.gradientBlue} 4.74%, ${color.oxfordBlue[900]} 51.16%); 
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color-dodge; 
    background-position: left bottom;
    .heading {
      color: ${color.common.white};
      font-weight: 900;
      letter-spacing: -0.02em;
      width: 100%;
      padding-right: 32px;
      @media (max-width: 992px) {
        padding-right: 0;
      }
      &.heading-1 {
        font-size: 48px;
        line-height: 60px;
        @media (max-width: 992px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &.heading-2 {
        font-size: 36px;
        line-height: 44px;
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .description {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: ${color.copy[200]}; 
      width: 95%;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
    `,
  light: `
    background: ${color.aliceBlue[50]};
    .heading {
      color: ${color.oxfordBlue[900]};
      font-weight: 900;
      letter-spacing: -0.02em;
      width: 100%;
      padding-right: 32px; 
      @media (max-width: 992px) {
        padding-right: 0;
        width: 100%;
      }
      @media (max-width: 576px) {
        font-size: 36px;
        line-height: 44px;
      }
      &.heading-1 {
        font-size: 48px;
        line-height: 60px;
        @media (max-width: 992px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &.heading-2 {
        font-size: 36px;
        line-heignt: 44px;
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .description {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: ${color.copy[700]}; 
      width: 95%;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
    `,
}

const curveType = {
  Convex: `
    min-height: 512px;
    align-items: center;
    background: url(${ConvexBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color-dodge; 
    background-position: center top;
    .heading {
      color: ${color.common.white};
      font-weight: 900;
      letter-spacing: -0.02em;
      width: 100%;
      padding-right: 32px;
      padding-bottom: 32px;
      @media (max-width: 992px) {
        padding-right: 0;
      }
      @media (max-width: 768px) {
        padding-bottom: 24px;
      }
      &.heading-1 {
        font-size: 48px;
        line-height: 60px;
        @media (max-width: 992px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &.heading-2 {
        font-size: 36px;
        line-heignt: 44px;
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .description {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: ${color.copy[200]}; 
      width: 95%;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
  `,
  Concave: `
    background: url(${ConcaveBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: color-dodge; 
    background-position: center top;
    @media (max-width: 992px) {
      background-position: center center;
    }
    .heading {
      color: ${color.common.white};
      text-align: center;
      font-weight: 900;
      letter-spacing: -0.02em;
      width: 100%;
      padding-bottom: 32px;
      @media (max-width: 992px) {
        padding-right: 0;
      }
      @media (max-width: 768px) {
        padding-bottom: 24px;
      }
      &.heading-1 {
        font-size: 48px;
        line-height: 60px;
        @media (max-width: 992px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &.heading-2 {
        font-size: 36px;
        line-heignt: 44px;
        @media (max-width: 576px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
    .description {
      font-weight: 300;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      color: ${color.copy[200]}; 
      width: 95%;
      @media (max-width: 992px) {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
      }
    }
  `,
}

const colors = {
  0: `
    background: ${color.primary[200]};
    color: black;
    margin-right: 12px;
    border: 1px solid ${color.primary[200]};
    &:hover {
      background: ${color.primary[400]};
      border: 1px solid ${color.primary[400]};
      color: black;
    }
    &:active {
      border: 1px solid ${color.common.purple};
    }
    &:focus {
      border: 1px solid ${color.common.purple};
    }
    @media (max-width: 576px) {
      margin-bottom: 12px;
    }
  `,
  1: `
    background: ${color.common.transparent};
    color: white;
    border: 1px solid white;
    &:hover {
      color: ${color.primary[200]};
      border: 1px solid ${color.primary[200]};;
    }
    &:active {
      border: 1px solid white;
    }
    &:focus {
      border: 1px solid white;
    }
  `,
}

export const Container = styled.div`
  display: flex;
  ${(props) => theme[props?.theme]}
  padding: 96px 0;
  justify-content: center;
  @media (max-width: 992px) {
    padding: 56px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
  }
  ${(props) =>
    props?.pricing &&
    css`
      padding: 48px 0;
    `}
  .eyebrow {
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${color.primary[700]};
    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
`

export const Button = styled(Link)`
  display: block;
  text-align: center;
  background: ${color.primary[200]};
  border-radius: 50px;
  color: ${color.oxfordBlue[900]};
  padding: 12px 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  transition: all 450ms ease-in-out;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background: ${color.primary[400]};
    color: ${color.oxfordBlue[900]};
  }
  @media screen and (min-width: 768px) {
    display: inline-block;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  ${(props) =>
    props.curveType === "Concave"
      ? `
        flex-direction: column;
        justify-content: flex-end;
        .conversion {
          margin: 0 auto;
        }
       `
      : `flex-direction: row;`}
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: 1200px) {
    margin: 0 15px;
  }
  ${(props) =>
    props?.pricing
      ? css`
          max-width: 1140px;
          @media (max-width: 1200px) {
            margin: 0;
          }
          @media (max-width: 992px) {
            flex-direction: column;
            margin: auto;
            max-width: 960px;
          }
          @media (max-width: 768px) {
            max-width: 720px;
          }
          @media (max-width: 576px) {
            max-width: 540px;
          }
        `
      : css`
          @media (max-width: 992px) {
            flex-direction: column;
            max-width: 770px;
            margin: auto;
          }
          @media (max-width: 768px) {
            max-width: 506px;
          }
        `};
  @media (max-width: 576px) {
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`

export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props?.pricing
      ? css`
          max-width: 800px;
          .description {
            width: 100%;
          }
        `
      : css`
          max-width: 770px;
        `};
  @media (max-width: 576px) {
    width: 95%;
  }
`

export const CtaContent = styled.div`
  ${(props) =>
    props.curveType && props.curveType === "Concave"
      ? "margin: 0 auto!important;"
      : "margin-left: auto;"}
  padding-top: 32px;
  white-space: nowrap;
  @media (max-width: 992px) {
    text-align: left;
    margin-right: auto;
    margin-left: 0;
  }
  @media (max-width: 576px) {
    width: 100%;
    a {
      width: 100%;
      font-size: 16px;
      @media (max-width: 576px) {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }
`

export const CurvedContainer = styled.div`
  display: flex;
  ${(props) => curveType[props?.curveType]}
  margin-top: ${(props) => (props?.negativeMarginTop ? "-124px" : null)};
  padding: 96px 0;
  @media (max-width: 992px) {
    padding: 56px 0;
    margin-top: ${(props) => (props?.negativeMarginTop ? "-56px" : null)};
  }
  @media (max-width: 768px) {
    padding: 40px 0;
    margin-top: ${(props) => (props?.negativeMarginTop ? "-40px" : null)};
  }
  .eyebrow {
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${color.primary[700]};
    @media (max-width: 576px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
`

export const ConvexButton = styled(Link)`
  background: ${color.logoYellow[500]};
  border-radius: 50px;
  color: black;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  transition: all 450ms ease-in-out;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background: ${color.logoYellow[300]};
    color: black;
  }
  &:active {
    background: ${color.logoYellow[600]};
  }
  &:focus {
    background: ${color.logoYellow[600]};
  }
`

export const ConcaveButton = styled(Link)`
  ${(props) => colors[props.color]}
  border-radius: 50px;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  transition: all 450ms ease-in-out;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`

export const CurvedTop = styled.img`
  width: 100%;
  margin-bottom: -1px;
  @media (max-width: 375px) {
    margin-bottom: -6px;
  }
`
