import React from "react"

import TopSwoop from "../../images/ConversionPanelBackgrounds/Top-Swoop.svg"

import ConversionPanelContent from "./components/ConversionPanel.Content"
import {
  ConcaveButton,
  ContentContainer,
  ConvexButton,
  CtaContent,
  CurvedContainer,
  CurvedTop,
} from "./styles/ConversionalPanelV2.styled"

const CurvedButton = ({ curveType, btn, color }) => {
  switch (curveType) {
    case "Convex":
      return <ConvexButton to={btn?.url}>{btn?.label}</ConvexButton>
    case "Concave":
      return (
        <ConcaveButton to={btn?.url} color={color}>
          {btn?.label}
        </ConcaveButton>
      )
    default:
      return null
  }
}

const ConversionPanelCurved = ({ component }) => {
  const negativeMarginTop = component?.negativeMarginTop
  const curveType = component?.curveType
  const callToAction =
    component?.conversionReference.length > 0 && component?.conversionReference

  return (
    <>
      {curveType === "Concave" && <CurvedTop src={TopSwoop} />}
      <CurvedContainer
        negativeMarginTop={negativeMarginTop}
        curveType={curveType}
      >
        <ContentContainer curveType={curveType}>
          <ConversionPanelContent component={component} />
          {callToAction && (
            <CtaContent curveType={curveType}>
              {callToAction.map((btn, index) => (
                <CurvedButton
                  curveType={curveType}
                  btn={btn}
                  key={index}
                  color={index}
                />
              ))}
            </CtaContent>
          )}
        </ContentContainer>
      </CurvedContainer>
    </>
  )
}

export default ConversionPanelCurved
