import React from "react"

import ConversionPanelCurved from "./ConversionPanel.Curved"
import ConversionPanelFullWidth from "./ConversionPanel.FullWidth"

const ConversionPanelV2 = ({ component, pricing }) => {
  const variant = component?.variant
  switch (variant) {
    case "Curved":
      return <ConversionPanelCurved component={component} />
    default:
      return (
        <ConversionPanelFullWidth pricing={pricing} component={component} />
      )
  }
}

export default ConversionPanelV2
