import React from "react"

import ConversionPanelContent from "./components/ConversionPanel.Content"
import {
  Container,
  ContentContainer,
  CtaContent,
  Button,
} from "./styles/ConversionalPanelV2.styled"

const ConversionPanelFullWidth = ({ component, pricing }) => {
  const bgClass = component?.background?.replace(/ /g, "").toLowerCase()
  const callToAction =
    component?.conversionReference?.length > 0 && component?.conversionReference

  return (
    <Container theme={bgClass} pricing={pricing}>
      <ContentContainer pricing={pricing}>
        <ConversionPanelContent component={component} pricing={pricing} />
        <CtaContent>
          {callToAction &&
            callToAction.map((btn, index) => (
              <Button to={btn?.url} key={index}>
                {btn?.label}
              </Button>
            ))}
        </CtaContent>
      </ContentContainer>
    </Container>
  )
}

export default ConversionPanelFullWidth
